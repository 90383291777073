<template>
  <div style="padding: 20px">
    <div class="dytitle">
      <span
        v-for="(item, index) in titlelist"
        :class="now == index ? 'dyactive' : ''"
        >{{ item }}</span
      >
    </div>
    <div class="num">
      订单编号：{{ num }}
      <span style="margin-right: 20px; cursor: pointer" @click="back"
        >返回列表</span
      >
    </div>
    <div>
      <steps :active="active"></steps>
      <trace :active="traceactive" :trace="trace"></trace>
      <articlecont :article="article"></articlecont>
      <div>
        <el-button @click="reuploade" class="btns">重新上传</el-button>
		<!--<el-button @click="drawback" type="danger" v-show='flag'>申请退款</el-button>-->
      </div>
    </div>
  </div>
</template>

<script>
import steps from '../components/steps'
import trace from '../components/trace'
import articlecont from '../components/articlecont'
import { checksteps, checktrace, checkarticle } from "../../../API/QKD/detail.js"
import { applyRefund} from "../../../API/ZZD/doreject.js"
export default {
  components: {
    steps,
    trace,
    articlecont,
  },
  data () {
    return {
	  flag:false,//申请退款
      now: "",
      attr: "",
      num: '',
      active: "0",
      trace: [],
      article: [],
      traceactive: "",
      titlelist: ["待缴费", "被驳回", "待确认"]
    }
  },
  mounted () {
    this.attr = this.$route.query.attr
    this.now = this.$route.query.now
    let id = this.$route.query.wid
    checksteps(id).then(res => {
      this.num = res.data.data.contributionnumber
      if (res.data.data.state == 0 || res.data.data.state == 1 || res.data.data.state == 71 || res.data.data.state == 72 || res.data.data.state == 81 || res.data.data.state == 82 || res.data.data.state == 54 || res.data.data.state == 55) {
        this.active = 2
      } else if (res.data.data.state == 21 || res.data.data.state == 22) {
        this.active = 2
      } else if (res.data.data.state == 30 || res.data.data.state == 31 || res.data.data.state == 5 || res.data.data.state == 6) {
        this.active = 2
      } else if (res.data.data.state == 32) {
        this.active = 4
      } else {
        this.active = 4
      }
    })
    checktrace(id).then(res => {
      this.trace = res.data.data
      this.traceactive = this.trace.length - 1
	  for(var i = 0; i<res.data.data.length;i++){
		  if(res.data.data[i].ispay){
			  this.flag = !this.flag
		  }else{
			  this.flag = !this.flag
		  }
	  }
    })
    checkarticle(id).then(res => {
      this.article = res.data.data
    })
  },
  methods: {
    reuploade () {
      this.$router.push({
        path: "/Pending/contributelist",
        query: {
          "wid": 2,
          "id": 0,
          works_id: this.$route.query.wid
        }
      })
    },
    back () {
      this.$router.push({
        path: "/Pending/todoorder",
        query: {
          "now": this.now,
          "id": 0
        }
      })
    },
	//申请退款
	drawback () {
	  applyRefund(this.$route.query.wid).then(res => {
	    if (res.data.code == 0) {
	      this.$message(res.data.data);
	      this.$router.push({
	        path: "/Pending/todoorder",
	      })
	    } else {
	      this.$message(res.data.data);
	    }
	  })
	  this.$confirm('退款将退回原支付方式，预计24小时到账', '申请退款', {
	    confirmButtonText: '确定',
	    cancelButtonText: '取消',
	    type: 'warning'
	  }).then(() => {
	    this.$message({
	      type: 'success',
	      message: '提交成功!'
	    });
	  }).catch(() => {
	    this.$message({
	      type: 'info',
	      message: '已取消'
	    });
	  });
	}, 
    drawbacks () {
      console.log(this.$route.query.wid);
      applyRefund(this.$route.query.wid).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.$message(res.data.data);
          this.$router.push({
            path: "/Pending/todoorder",
          })
        } else {
          this.$message(res.data.data);
        }
      })
    },
  }
}
</script>

<style>
.num {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.detailBottom {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}

.dytitle {
  border-bottom: 1px solid #f7f7f7;
  font-size: 14px;
  line-height: 60px;
}

.dytitle span {
  margin: 0 10px;
  padding: 0 5px;
  display: inline-block;
}

.dyactive {
  border-bottom: 1px solid red;
  margin: 0 10px;
  padding: 0 5px;
  display: inline-block;
  color: red;
}

.bottombtn {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}
</style>